import { defineFeatureFlag } from './featureFlagDefintionHelpers';

// Set in console like so - document.cookie = 'm2_dashboard=true';

export const posthogFeatureFlagDefinitions = {
  ApplicationTaskCompleteModal: defineFeatureFlag('application_task_complete_modal', [
    'control',
    'modal',
  ]),
  OfferEstimatorVideoPlayer: defineFeatureFlag('offer_estimator_video_player', [
    'control',
    'video',
  ]),
  PostAppDocUploadFollowup: defineFeatureFlag('post_app_doc_upload_followup', [false, true]),
  PostFundingCalc: defineFeatureFlag('post_funding_calc', ['disabled', 'enabled']),
  PrequalContact: defineFeatureFlag('cr10b-pqflow-contact', ['control', 'test']),
  ApplicationHEIAmount: defineFeatureFlag('cr15-app-offer-amount-quick-wins', ['control', 'test']),
  ApplicationCompleteAnimation: defineFeatureFlag('cr18-app-completion-animation', [
    'control',
    'test',
  ]),
  TestExperiment20230508: defineFeatureFlag('test_experiment_20230508', ['control', 'test1']),
  SelfServeOfferAmount: defineFeatureFlag('cr16-self-serve-offer-amt', ['control', 'test']),
  _test_flag: defineFeatureFlag('__test_flag__', ['first_value', 'other_value']),
};
