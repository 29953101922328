import { z } from 'zod';
import { ProductTypeEnum } from './commonTypes';

/**
 * NOTE: These types are shared between Customer Portal and Homeowner Service. At the moment, both repos have their own copy
 * of the file. Any changes to these types will need to be reflected in both repos:
 *
 * Customer Portal: src/services/apiTypes/prequalTypes.ts
 * Homeowner Service: src/apiTypes/prequalTypes.ts
 */

export enum PricingFeeMethod {
  Fixed = 'fixed',
  Rate = 'rate',
}

export const pricingFeeSchema = z
  .object({
    key: z.string(),
    label: z.string(),
    amount: z.number(),
    notes: z.string().optional(),
    method: z.nativeEnum(PricingFeeMethod),
  })
  .passthrough();
export type PricingFee = z.infer<typeof pricingFeeSchema>;

export const closingCostsSchema = z
  .object({
    total_fees: z.number(),
    total_payoffs: z.number(),
    fees: z.array(pricingFeeSchema),
    // "payoffs" field is not yet implemented on Prequal and will always be an empty array:
    payoffs: z.array(z.never()).optional(),
  })
  .passthrough();
export type ClosingCosts = z.infer<typeof closingCostsSchema>;

export const pricingSchema = z
  .object({
    acceptable: z.boolean(),
    capPercentage: z.number(),
    closingCosts: closingCostsSchema.optional(),
    homeValue: z.number(),
    optionInvestmentAmount: z.number(),
    optionPercentage: z.number(),
    originationFeeRate: z.number(),
    riskAdjustedHomeValue: z.number(),
    riskAdjustment: z.number(),
    termYears: z.number(),
  })
  .passthrough();
export type Pricing = z.infer<typeof pricingSchema>;

// GET /api/v2/prequal/estimates/.../pricing
export const pricingRangeResponseSchema = z.array(pricingSchema);
export type PricingRangeResponse = z.infer<typeof pricingRangeResponseSchema>;

export enum OfferStatus {
  Ineligible = 'ineligible',
  Eligible = 'eligible',
  Rejected = 'rejected',
}

export const estimateLinksSchema = z
  .object({
    api: z.string().url().nullable().optional(),
    html: z.string().url().nullable().optional(),
    calculator: z.string().url().nullable().optional(),
    application: z.string().url().nullable().optional(),
  })
  .passthrough();
export type EstimateLinks = z.infer<typeof estimateLinksSchema>;

export enum SourceChannel {
  Email = 'email',
  FollowUpEstimateCalculator = 'followupestimatecalculator',
  GeneralContractor = 'generalcontractor',
  Marketing = 'marketing',
  Partners = 'partners',
}

export enum SourceOrigin {
  API = 'api',
  KBYG = 'kbyg',
  Prequal = 'prequal',
  Salesforce = 'salesforce',
  Underwrite = 'underwrite',
}

export const estimateSourceSchema = z
  .object({
    channel: z.union([z.nativeEnum(SourceChannel), z.string()]).nullable(),
    platform: z.string().nullable(),
    partnerAccountId: z.string().nullable(),
    origin: z.union([z.nativeEnum(SourceOrigin), z.string()]).nullable(),
    referenceId: z.string().nullable(),
  })
  .passthrough();
export type EstimateSource = z.infer<typeof estimateSourceSchema>;

export const helocPricingOptionSchema = z
  .object({
    maxLineAmount: z.number(),
    interestRate: z.number(),
  })
  .passthrough();

export const estimateSchema = z
  .object({
    productType: ProductTypeEnum,
    key: z.string(),
    submissionKey: z.string().nullable().optional(),
    status: z.nativeEnum(OfferStatus),
    problems: z.array(z.unknown()).optional(),
    expires: z.string().datetime({ offset: true }),
    requestedOptionAmount: z.number().optional(),
    minOptionAmount: z.number().optional(),
    maxOptionAmount: z.number().optional(),
    mostRecentEstimateKey: z.string().nullable().optional(),
    canSave: z.boolean().optional(),
    links: estimateLinksSchema,
    pricing: pricingSchema.optional(),
    source: estimateSourceSchema.optional(),
    utmParameters: z.record(z.string()).optional(),
    heiEligible: z.boolean().optional(),
    heiEligibleAmount: z.number().optional(),
    drawPeriodYears: z.number().optional(),
    repaymentPeriodYears: z.number().optional(),
    aprCeiling: z.number().optional(),
    aprMaxAnnualRateChange: z.number().optional(),
    minLoanAmount: z.number().optional(),
    maxLoanAmount: z.number().optional(),
    openingFee: z.number().optional(),
    minimumInitialDraw: z.number().optional(),
    requestedAmount: z.number().nullable().optional(),
    pricingOptions: z.array(helocPricingOptionSchema).optional(),
    homebuyerSavings: z.number().optional(),
    minPropertyValue: z.number().optional(),
    maxPropertyValue: z.number().optional(),
    maxHomebuyerAmount: z.number().optional(),
    termYears: z.number().optional(),
  })
  .passthrough();
export type Estimate = z.infer<typeof estimateSchema>;

export const estimateApplicantSchema = z
  .object({
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    phone: z.string(),
    contactPolicyConsentDate: z.string().datetime({ offset: true }).nullable(),
  })
  .passthrough();
export type EstimateApplicant = z.infer<typeof estimateApplicantSchema>;

export const estimateAddressSchema = z
  .object({
    streetAddress: z.string(),
    unit: z.string().nullable().optional(),
    city: z.string(),
    state: z.string(),
    zip: z.string(),
    fipsCode: z.string().nullable().optional(),
  })
  .passthrough();
export type EstimateAddress = z.infer<typeof estimateAddressSchema>;

export const estimatePropertySchema = z
  .object({
    address: estimateAddressSchema,
  })
  .passthrough();
export type EstimateProperty = z.infer<typeof estimatePropertySchema>;

export const estimateApplicationSchema = z
  .object({
    key: z.string(),
    expires: z.string().datetime({ offset: true }),
    applicant: estimateApplicantSchema,
    property: estimatePropertySchema.optional(),
  })
  .passthrough();
export type EstimateApplication = z.infer<typeof estimateApplicationSchema>;

// GET /api/v2/prequal/estimates/...
export const prequalEstimatesResponseSchema = z.object({
  estimate: estimateSchema,
  application: estimateApplicationSchema,
  features: z.record(z.string()).optional(),
});
export type PrequalEstimatesResponse = z.infer<typeof prequalEstimatesResponseSchema>;
