export default {
  haveYouYourSpouseOrAnyOwner:
    'Have you, your spouse, or any owner of the property ever been convicted or pled guilty to a felony or misdemeanor in relation to financial crimes?',
  areThereAnyOutstandingJudgments:
    'Are there any outstanding judgments against you, your spouse, or any owner of the property?',
  areYouYourSpouseOrAnyOwnerPlanning:
    'Are you, your spouse, or any owner of the property planning to file for bankruptcy, presently involved in any bankruptcy, or have received a bankruptcy discharge or dismissal within the past 2 years?',
  haveYouYourSpouseOrAnyOwnerDirectlyOrIndirectly:
    'Have you, your spouse, or any owner of the property directly or indirectly been obligated on any loan which resulted in foreclosure, transfer of title in lieu of foreclosure, or judgment?',
  doYouYourSpouseOrAnyOwnerPlan:
    'Do you, your spouse, or any owner of the property plan to close any new mortgages, home equity loans, HELOCs, HERO loans, PACE loans, or any other type of solar or energy-efficiency loans prior to receiving funds from Point? This includes any new debts or liens secured by your property.',
  tellUsHowMuchMoney:
    'Tell us how much money you are looking for and how you will use the Point funds. The amount of funding will impact what you pay in fees and the final repayment.',
  howMuchMoneyWouldYouLike: 'How much money would you like from point?',
  howWillYouUseTheseFunds: 'How will you use these funds?',
  pleaseExplainInFurtherDetail: 'Please explain in further detail how you will use these funds.',
  chooseOne: 'Choose one',
};
