import { z, enum as zodEnum } from 'zod';
import { ApplicationStatusEnum, DocketStatusEnum } from './commonTypes';
import { userSchema } from './middlewareTypes';
import { taskSchema, TaskStatus } from './taskTypes';
import {
  dashboardErrorResponseSchema,
  dashboardSuccessResponseSchema,
  AutomatedDocketQualificationStatus,
  automatedDocketQualificationStatusSchema,
} from './underwriteTypes';

/**
 * NOTE: These types are shared between Customer Portal and Homeowner Service. At the moment, both repos have their own copy
 * of the file. Any changes to these types will need to be reflected in both repos:
 *
 * Customer Portal: src/services/apiTypes/homeownerTypes.ts
 * Homeowner Service: src/apiTypes/homeownerTypes.ts
 */

export { ApplicationStatus, DocketStatus } from './commonTypes';

export const msgRequiredField = 'This field is required';

export enum CitizenshipStatus {
  US = 'US Citizen',
  Alien = 'Permanent Resident Alien',
  Visa = 'Visa Holder',
  None = 'None of the above',
}
const CitizenshipStatusEnum = z.nativeEnum(CitizenshipStatus);

export enum EmploymentStatus {
  SelfEmployed = 'Self-employed',
  Employed = 'Employed',
  NotEmployed = 'Not employed',
  Retired = 'Retired',
}
const EmploymentStatusEnum = z.nativeEnum(EmploymentStatus);

export enum OwnershipStatus {
  Individual = 'As an individual',
  JointlyWithSpouse = 'Jointly with a spouse/domestic partner',
  JointlyWithOther = 'Jointly with someone other than a spouse/domestic partner',
  JointlyWithMultiple = 'Jointly with more than 2 owners',
  Trust = 'Through a trust',
  LLC = 'Through an LLC',
  LP = 'Through an LP',
}

export enum IntendedUse {
  DebtRepayment = 'Debt repayment',
  SmallBusiness = 'Small business',
  HomeRemodeling = 'Home remodeling',
  InvestmentProperty = 'Investment property',
  EducationExpenses = 'Education expenses',
  Other = 'Other',
}
const IntendedUseEnum = z.nativeEnum(IntendedUse);

export enum ReferralSource {
  Broker = 'Broker or contractor',
  SocialMedia = 'Social media (e.g., Facebook)',
  Financial = 'Financial website (e.g., Credit Karma)',
  TV = 'TV',
  USPS = 'USPS mail',
  Friend = 'Friend',
  Radio = 'Radio / podcast',
  Google = 'Google / Bing',
  Other = 'Other',
}
const ReferralSourceEnum = z.nativeEnum(ReferralSource);

export const contactInfoSchema = z.object({
  firstName: z.string().min(1),
  middleName: z.string().optional(),
  lastName: z.string().min(1),
  email: z.string().min(1).email(),
  phone: z
    .string()
    .transform((value) => value.replace(/\D/g, ''))
    .refine(
      (value) => value.match(/^\d{10}$/) !== null,
      (value) => {
        return { message: value ? 'Phone number is invalid' : msgRequiredField };
      }
    ),
});
export type ContactInfo = z.infer<typeof contactInfoSchema>;

export const spouseContactInfoSchema = z.object({
  firstName: z.string().min(1),
  middleName: z.string().optional(),
  lastName: z.string().min(1),
});
export type SpouseContactInfo = z.infer<typeof spouseContactInfoSchema>;

export const addressSchema = z.object({
  streetAddress: z.string(),
  unit: z.string().nullable().optional(),
  city: z.string(),
  countyFipsCode: z.string().optional(),
  state: z.string(),
  zip: z.string(),
});

export type Address = z.infer<typeof addressSchema>;

export const yesNoExplainFieldSchema = z.discriminatedUnion('answer', [
  z.object({
    answer: z.literal(false),
    explanation: z.string().optional(),
  }),
  z.object({
    answer: z.literal(true),
    explanation: z.string().min(1),
  }),
]);

export const spouseFieldsSchema = z.discriminatedUnion('answer', [
  z.object({
    answer: z.literal(false),
  }),
  z.object({
    answer: z.literal(true),
    isOnTitle: z.boolean(),
    contactInfo: spouseContactInfoSchema,
  }),
]);

export const propertyHasLoanSchema = z.discriminatedUnion('answer', [
  z.object({
    answer: z.literal(false),
  }),
  z.object({
    answer: z.literal(true),
    isHelocWithinDrawPeriod: z.discriminatedUnion('answer', [
      z.object({
        answer: z.literal(false),
        currentLoanBalance: z.number().refine((value) => value >= 0),
      }),
      z.object({
        answer: z.literal(true),
        totalCreditLimit: z.number().refine((value) => value >= 0),
      }),
    ]),
  }),
]);

export const hoaSchema = z.discriminatedUnion('answer', [
  z.object({
    answer: z.literal(false),
  }),
  z.object({
    answer: z.literal(true),
    pendingLitigation: yesNoExplainFieldSchema,
    delinquent: yesNoExplainFieldSchema,
  }),
]);

export const primaryResidenceSchema = z.discriminatedUnion('answer', [
  z.object({
    answer: z.literal(true),
  }),
  z.object({
    answer: z.literal(false),
    primaryAddress: z.string().min(1),
    ownsPrimaryAddress: z.boolean(),
  }),
]);

export const missedLastMortgagePaymentSchema = z.discriminatedUnion('answer', [
  z.object({
    answer: z.literal(false),
  }),
  z.object({
    answer: z.literal(true),
    agreeToPayoff: z.boolean(),
    howManyMortgagePaymentsMissed: z.object({
      answer: z.enum(['most_recent', 'two_or_more']),
      explanation: z.string().min(1),
    }),
  }),
]);

export const balanceConfirmationSchema = z.string().min(1);
export const zeroMortgageSchema = z.object({
  mortgageBalance: z.literal(0),
  balanceConfirmation: balanceConfirmationSchema.optional(),
});
export type ZeroMortgage = z.infer<typeof zeroMortgageSchema>;
export const mortgageMaxAmount = 10_000_000;
export const mortgageBalanceSchema = z
  .number()
  .min(0)
  .max(mortgageMaxAmount, { message: 'Mortgage balance should be below $10,000,000' });
export const nonZeroMortgageSchema = z.object({
  mortgageBalance: mortgageBalanceSchema,
  balanceConfirmation: balanceConfirmationSchema.optional(),
  hasMortgageModification: yesNoExplainFieldSchema,
  hasActiveForbearance: z.boolean(),
  missedPayment: missedLastMortgagePaymentSchema,
  hasDeferredPayment: yesNoExplainFieldSchema,
});
export type NonZeroMortgage = z.infer<typeof nonZeroMortgageSchema>;

export const mortgageSchema = z.union([zeroMortgageSchema, nonZeroMortgageSchema]);

export const additionalOwnerSchema = contactInfoSchema.pick({ firstName: true, lastName: true });

export type AdditionalOwner = z.infer<typeof additionalOwnerSchema>;
export const additionalOwnersSchema = z.array(additionalOwnerSchema);
export type AdditionalOwners = z.infer<typeof additionalOwnersSchema>;

export const ownershipStatusWithOthersEnumSchema = zodEnum([
  OwnershipStatus.JointlyWithOther,
  OwnershipStatus.JointlyWithMultiple,
]);
export type OwnershipStatusWithOthersEnum = z.infer<typeof ownershipStatusWithOthersEnumSchema>;
export const ownershipSchemaWithOwnersSchema = z.object({
  ownershipStatus: ownershipStatusWithOthersEnumSchema,
  additionalOwners: additionalOwnersSchema.min(1),
});
export type OwnershipSchemaWithOwners = z.infer<typeof ownershipSchemaWithOwnersSchema>;

export const ownershipSchemaWithoutOwnersSchema = z.object({
  ownershipStatus: zodEnum([
    OwnershipStatus.Individual,
    OwnershipStatus.JointlyWithSpouse,
    OwnershipStatus.LLC,
    OwnershipStatus.LP,
    OwnershipStatus.Trust,
  ]),
});
export type OwnershipSchemaWithoutOwners = z.infer<typeof ownershipSchemaWithoutOwnersSchema>;

export const ownershipSchema = z.union([ownershipSchemaWithOwnersSchema, ownershipSchemaWithoutOwnersSchema]);

export const applicantSignatureSchema = z.string();

export const socialSecurityNumberSchema = z.string().refine(
  (value) => {
    // Filter out obvious fakes and invalid SSN patterns. Fancy regex copied from Underwrite's SSN validator.
    if (['123-45-6789', '012-34-5678'].includes(value)) return false;
    const pattern = /^(?!(\d)\1{2}-\1{2}-\1{4})(?!000|666|9)[0-9]{3}([ -]?)(?!00)[0-9]{2}\2(?!0000)[0-9]{4}$/;
    return pattern.test(value);
  },
  (value) => ({
    message: value ? 'Social Security number is invalid' : msgRequiredField,
  })
);

export const heiApplicationSchema = z.object({
  applicant: z.object({
    contactInfo: contactInfoSchema,
    ssn: socialSecurityNumberSchema,
    birthdate: z.string().refine(
      (value) => {
        const pattern = /^\d{4}-\d{2}-\d{2}$/;
        return pattern.test(value);
      },
      { message: 'Invalid date' }
    ),
    citizenshipStatus: CitizenshipStatusEnum,
    employmentStatus: EmploymentStatusEnum,
    referral: ReferralSourceEnum.optional(),
    hasSpouse: spouseFieldsSchema,
    signature: applicantSignatureSchema,
  }),
  property: z.object({
    addressOneLine: z.string(),
    ownership: ownershipSchema,
    isPrimaryResidence: primaryResidenceSchema,
    isMobileHome: z.boolean(),
    mortgage: mortgageSchema,
    hasLoan: propertyHasLoanSchema,
    hasEnergyEfficiencyLoan: yesNoExplainFieldSchema,
    hasPendingLiensOrJudgments: yesNoExplainFieldSchema,
    hoa: hoaSchema,
    isDelinquentOnPropertyTaxes: yesNoExplainFieldSchema,
    hasHazardousSubstances: yesNoExplainFieldSchema,
    hasEnvironmentalLawViolations: yesNoExplainFieldSchema,
    hasImpairedPropertyValue: yesNoExplainFieldSchema,
    hasUnpermittedWorks: yesNoExplainFieldSchema,
    hasAccessoryDwellingUnit: yesNoExplainFieldSchema,
    isInRenovation: yesNoExplainFieldSchema,
    hasPendingLawsuits: yesNoExplainFieldSchema,
  }),
  financials: z.object({
    hasFelony: yesNoExplainFieldSchema,
    hasJudgments: yesNoExplainFieldSchema,
    hasBankruptcy: yesNoExplainFieldSchema,
    hasForeclosure: yesNoExplainFieldSchema,
    planningToCloseLoans: yesNoExplainFieldSchema,
    amountRequested: z
      .number()
      .min(25_000, { message: 'Amount requested must be at least $25,000' })
      .max(1_000_000, { message: 'Amount requested cannot be more than $1,000,000' }),
    intendedUse: IntendedUseEnum,
    intendedUseDetail: z.string().min(1),
  }),
});
export type HeiApplication = z.infer<typeof heiApplicationSchema>;

export const inProgressHeiApplicationSchema = heiApplicationSchema.deepPartial();
export type InProgressHeiApplication = z.infer<typeof inProgressHeiApplicationSchema>;

export const heiApplicationConsentSchema = z.object({
  employmentVerificationAuthorize: z.string().min(1),
  creditCheckAuthorize: z.string().min(1),
  escrowDisclosureAuthorize: z.string().min(1),
  contractorSharingAuthorize: z.string().min(1),
  creditCheckTerm: z.string().min(1),
});
export type HeiApplicationConsent = z.infer<typeof heiApplicationConsentSchema>;

// GET /application/:estimateKey
export const GetApplicationResponseSchema = z.object({
  application: inProgressHeiApplicationSchema,
  submittedAt: z.date().nullable(),
});
export type GetApplicationResponse = z.infer<typeof GetApplicationResponseSchema>;

// POST /application
export const postApplicationRequestSchema = z.object({
  applicant: z.object({
    contactInfo: contactInfoSchema,
  }),
  property: z.object({
    normalizedAddress: addressSchema,
  }),
  financials: z
    .object({
      amountRequested: z.number(),
    })
    .optional(),
  docketId: z.number().optional(),
  estimateKey: z.string().optional(),
});
export type PostApplicationRequest = z.infer<typeof postApplicationRequestSchema>;

// POST /application/submit
export const postApplicationSubmitRequestSchema = z.object({
  html: z.string().min(1),
  application: heiApplicationSchema,
  consents: heiApplicationConsentSchema,
});
export type PostApplicationSubmitRequest = z.infer<typeof postApplicationSubmitRequestSchema>;

const validationErrorsSchema = z.record(z.string(), z.string());

export type ValidationErrors = z.infer<typeof validationErrorsSchema>;

export const ApplicationApiResponseSchema = z.union([
  z.object({
    validationErrors: validationErrorsSchema.optional(),
  }),
  z.string(),
]);

export type ApplicationApiResponse = z.infer<typeof ApplicationApiResponseSchema>;

const getApplicationNotFoundResponseSchema = z.object({
  followUpUrl: z.string().optional(),
  applicationStatus: ApplicationStatusEnum.optional(),
  docketStatus: DocketStatusEnum,
});

export type GetApplicationNotFoundResponse = z.infer<typeof getApplicationNotFoundResponseSchema>;

export const hosDashboardTaskSchema = taskSchema;
export type HosDashboardTask = z.infer<typeof hosDashboardTaskSchema>;

export const hosDashboardSuccessResponseSchema = dashboardSuccessResponseSchema.omit({ account_manager: true }).extend({
  account_manager: z.union([z.string(), userSchema, z.null()]),
  tasks: z.array(hosDashboardTaskSchema).optional(),
});
export type HosDashboardSuccessResponse = z.infer<typeof hosDashboardSuccessResponseSchema>;

export const hosDashboardResponseSchema = z.union([hosDashboardSuccessResponseSchema, dashboardErrorResponseSchema]);
export type HosDashboardResponse = z.infer<typeof hosDashboardResponseSchema>;

export const hosPresignedPostSchema = z.object({
  key: z.string().uuid(),
  url: z.string().url(),
  fields: z.record(z.string()),
});
export type HosPresignedPost = z.infer<typeof hosPresignedPostSchema>;

export enum LogSeverity {
  Debug = 1,
  Verbose = 2,
  Info = 3,
  Warn = 4,
  Error = 5,
  Critical = 6,
}
export const LogSeverityEnum = z.nativeEnum(LogSeverity);

export const BrowserLogRequest = z.object({
  severity: LogSeverityEnum,
  message: z.record(z.unknown()),
});
export type BrowserLog = z.infer<typeof BrowserLogRequest>;

// The following UW types are expected to get full definitions as part of HOS in the future.
// For now, re-export them as part of homeownerTypes to minimize code churn when that happens.
export { FollowUpCategory } from './underwriteTypes';

export { AutomatedDocketQualificationStatus };

export const applicationStatusResponseSchema = z.object({
  status: z.nativeEnum(TaskStatus).nullable(),
  adqStatus: automatedDocketQualificationStatusSchema.nullable(),
});
export type ApplicationStatusResponse = z.infer<typeof applicationStatusResponseSchema>;
