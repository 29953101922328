export default {
  birthdate: 'Birthdate',
  month: 'Month',
  date: 'Date',
  year: 'Year',

  citizenshipStatus: 'Citizenship status',
  whatIsYourPrimary: 'What is your primary employment status?',
  howDidYou: 'How did you hear about us?',
  chooseOne: 'Choose one',

  areYouCurrently:
    'Are you currently legally married or in a legally recognized domestic partnership?',
  noteIfYouAre:
    'NOTE: If you are legally married or in a legally recognized domestic partnership then your spouse or partner will need to sign your Point closing documents.',
  isYourSpouse: 'Is your spouse on the title of the property?',

  legalName: 'Legal name',
  firstName: 'First name',
  middleName: 'Middle name',
  lastName: 'Last name',
  email: 'Email',
  phoneNumber: 'Phone number',
  errorDuplicateEmail: 'Spouse email cannot match primary applicant email',

  howMuchMoney: 'How much money would you like to receive?',
  youHavePrequalified:
    'You have prequalified for up to %optionAmount%. You can request an amount from $25,000 to %optionAmount%. This is an estimate only and may change based on your application and home appraisal',
  howWillYouUseTheseFunds: 'How will you use these funds?',
  pleaseExplainInFurtherDetail: 'Please explain in further detail how you will use these funds.',
};
