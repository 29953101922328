import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { z } from 'zod';
import { Checkbox, InputMaskType, Size, normalizeToNumber } from '@pointdotcom/pds';
import { PaginatorProps } from 'components/LeftRightPaginator';
import { useHomeownerApplication } from 'containers/hooks/useHomeownerApplication';
import useCurrentValueRef from 'hooks/useCurrentValueRef';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import { logError, logWarning } from 'lib/logger';
import OfferEstimateModel from 'models/OfferEstimateModel';
import { parseQueryError, parseResponse } from 'services/api/baseQuery';
import { ApplicationApiResponseSchema, ValidationErrors } from 'services/apiTypes/homeownerTypes';
import { getApplicationData } from 'store/productApplication';
import { ApplicationPageFooterNav } from '../ApplicationPageFooter';
import CorrectionsModal from '../CorrectionsModal';
import {
  ApplicationPageType,
  FormTemplateOptions,
  pageTemplateMap,
  pageTitleMap,
} from '../constants';
import FormTemplate, {
  getFieldsFromTemplate,
  getInvalidFormTemplate,
  getPathIsChild,
} from './FormComponents/FormTemplate';
import {
  baseFieldValidation,
  getHelpTextFromZodIssue,
  getZodIssueFromBEResponse,
} from './FormComponents/validationUtils';
import { getFormAsString } from './HiddenAuditForm';
import {
  FieldProps,
  FormStructure,
  FormStructureField,
  GetBaseFormFieldPropsFunc,
  GetFieldValueByPathFunc,
  HandleChangeFunc,
  HandleFocusFunc,
  ShouldShowErrorForFieldFunc,
  SubmitApplicationError,
  ValidationFunc,
  ValidationProps,
} from './constants';
import * as styles from './styles';

// Responsibilities of this page -
// 1. set the order of the form pages
// 2. determine the form template
// 3. style the container that the form templates fall into

interface ApplicationFormProps extends Partial<PaginatorProps> {
  page: ApplicationPageType;
  estimate?: OfferEstimateModel;
  onApplicationSubmitted?: () => Promise<void>;
  isProcessing?: boolean;
}

const ApplicationForm = ({
  page,
  onPaginate: onPaginate = () => null,
  currentPageNum,
  totalNumPages,
  estimate,
  onApplicationSubmitted,
  isProcessing,
}: ApplicationFormProps) => {
  const [hasBeValidationErrors, setHasBeValidationErrors] = useState(false);
  const [correctionsModalOpen, setCorrectionsModalOpen] = useState(false); // the corrections modal open state
  const [hiddenErrorPaths, setHiddenErrorPaths] = useState<Array<string>>([]); // field by field error hiding (on focus)
  const showErrorsRef = useRef<boolean>(false); // show red outlines on fields
  const showErrorMessagesRef = useRef<boolean>(false); // show the helptext (set to true for testing)
  const data = useSelector(getApplicationData);
  const correctionsTemplate = useRef<FormStructure>([]);
  const correctionsModalRef = useRef<HTMLDivElement>(null);
  const isApplicationHEIAmountTestEnabled =
    useFeatureFlag(FeatureFlag.ApplicationHEIAmount) === 'test';

  const {
    applicationErrorMessage,
    submitHomeownerApplication,
    updateApplicationData,
    submitApplicationState,
  } = useHomeownerApplication();

  const templateOptions: FormTemplateOptions = useMemo(
    () => ({
      isApplicationHEIAmountTestEnabled,
      estimate,
    }),
    [isApplicationHEIAmountTestEnabled, estimate]
  );

  const handleChange: HandleChangeFunc = useCallback(
    (fieldOrPath) =>
      (e, { value }) => {
        let formattedValue: string | number | boolean = value;
        const isFieldProp = typeof fieldOrPath === 'object';
        const isPathProp = typeof fieldOrPath === 'string';
        const path = isPathProp
          ? (fieldOrPath as string)
          : (fieldOrPath as FormStructureField).path;

        const isNumberFormatted =
          isFieldProp && (fieldOrPath as FormStructureField)?.props?.mask === InputMaskType.Number;

        if (isNumberFormatted) {
          formattedValue = value.length ? normalizeToNumber(value) : '';
        } else if (value === 'true' || value === 'false') {
          formattedValue = value === 'true';
        }

        // this handles the unchecking of a checkbox component
        const field = fieldOrPath as FormStructureField;
        if (
          field.Component === Checkbox &&
          field.props?.type === 'checkbox' &&
          field.props?.checkVal === field.props?.value
        ) {
          formattedValue = '';
        }
        updateApplicationData(path, formattedValue);
      },
    [updateApplicationData]
  );

  const handleFocus: HandleFocusFunc = useCallback(
    (path) => () => {
      if (showErrorsRef.current) {
        const paths = [...hiddenErrorPaths];
        if (paths.indexOf(path) === -1) {
          paths.push(path);
        }
        setHiddenErrorPaths(paths);
      }
    },
    [hiddenErrorPaths]
  );

  const getFieldValueByPath = useCallback<GetFieldValueByPathFunc>(
    (path) => {
      const keys = path.split('.');
      return keys.reduce((value: TSFixMe, key: string) => {
        if (value && typeof value === 'object' && key in value) {
          return value[key];
        }
        return undefined; // Path is not valid, return undefined
      }, data);
    },
    [data]
  );

  const getInvalidFormTemplateFromAllTemplates = useCallback(
    (paths?: Array<string>) => {
      const invalidTemplate: FormStructure = [];
      Object.keys(pageTemplateMap).forEach((applicationPageType) => {
        const pageKey = applicationPageType as ApplicationPageType;
        const pageTitle = pageTitleMap[pageKey];
        const formTemplate = pageTemplateMap[pageKey](templateOptions);
        const invalidFormTemplates = getInvalidFormTemplate({
          template: formTemplate,
          sectionName: pageTitle,
          getFieldValueByPath,
          paths,
        });
        if (invalidFormTemplates) {
          invalidTemplate.push(...invalidFormTemplates);
        }
      });
      return invalidTemplate;
    },
    [getFieldValueByPath, templateOptions]
  );

  const handleCorrectionModalClose = () => {
    showErrorsRef.current = false;
    correctionsTemplate.current = [];
    setCorrectionsModalOpen(false);
    setHiddenErrorPaths([]);
  };

  const getPathWasFocused = useCallback(
    (path: string) => {
      return hiddenErrorPaths.indexOf(path) > -1;
    },
    [hiddenErrorPaths]
  );

  function processValidationErrors(payloadErrors: ValidationErrors = {}): ValidationErrors {
    const processedErrors: ValidationErrors = {};

    // strip the "application." prefix from any keys in the validationErrors object
    Object.entries(payloadErrors).forEach(([key, value]) => {
      const strippedKey = key.replace(/^application./, '');
      processedErrors[strippedKey] = value;
    });

    return processedErrors;
  }

  // Logic to show the error in the UI
  // Considers:
  // If the form was submitted, if the field was focused or not
  const shouldShowErrorForField: ShouldShowErrorForFieldFunc = useCallback(
    ({ field }) => {
      // if a modal form submit attempt hasnt been made (showErrors(true) is set there)
      if (!showErrorsRef.current) {
        return false;
      }

      // if their path was logged in the hiddenErrorPaths state after focus
      if (field.path && getPathWasFocused(field.path)) {
        return false;
      }

      return true;
    },
    [getPathWasFocused]
  );

  const getValidatorFromBEResponse = useCallback(
    ({ field }: ValidationProps): ValidationFunc | null => {
      if (!submitApplicationState.error) {
        return null;
      }

      const { data: errorData } = submitApplicationState.error as SubmitApplicationError;

      if (typeof errorData === 'object' && field?.path) {
        const validationErrorPathArray = Object.keys(
          processValidationErrors(errorData.validationErrors)
        );
        const exactPathMatch = validationErrorPathArray.includes(field.path); // if the path is included in the array of errors
        const pathIsChild = getPathIsChild({
          checkPaths: validationErrorPathArray,
          path: field.path,
        }); // if the array of errors contains a parent path, and the field.path is a child

        if (exactPathMatch || pathIsChild) {
          return () =>
            getZodIssueFromBEResponse(pathIsChild ? { params: { associated: true } } : undefined);
        }

        if (pathIsChild) {
          logWarning({
            eventType: 'applicationResponseWithErrors',
            detail: {
              errorData,
              message: 'Error returned from POST /application contained parent paths',
            },
          });
        }
      }
      return null;
    },
    [submitApplicationState.error]
  );

  // props and utility functions that get passed into each form field component
  const getBaseFormFieldProps: GetBaseFormFieldPropsFunc = useCallback(
    (field) => {
      const value = getFieldValueByPath(field.path);
      const validator =
        getValidatorFromBEResponse({ field }) || field.validation || baseFieldValidation;
      const validationResult = validator({
        fieldValue: getFieldValueByPath(field.path),
        field,
        getFieldValueByPath,
      });

      const issues: Array<z.ZodIssue> = [];
      if (validationResult instanceof z.ZodError) {
        issues.push(...validationResult.issues);
      }
      const [issue] = issues;
      const associatedIssue = (issue as z.ZodCustomIssue)?.params?.associated === true;
      const error = issue && !associatedIssue && shouldShowErrorForField({ field });

      const helptext =
        issue && !associatedIssue && showErrorMessagesRef.current === true
          ? getHelpTextFromZodIssue(issue, field)
          : '';

      return {
        path: field.path,
        issues,
        field,
        onChange: handleChange(field),
        getFieldValueByPath,
        handleChange,
        handleFocus,
        getPathWasFocused,
        shouldShowErrorForField,
        shouldShowErrors: shouldShowErrorForField({ field }),
        shouldShowErrorMessages: showErrorMessagesRef.current,
        value,
        styleSize: Size.Small,
        onFocus: handleFocus(field.path),
        error,
        helptext,
      } as FieldProps;
    },
    [
      getFieldValueByPath,
      getPathWasFocused,
      getValidatorFromBEResponse,
      handleChange,
      handleFocus,
      shouldShowErrorForField,
    ]
  );

  const onApplicationSubmittedRef = useCurrentValueRef(onApplicationSubmitted);

  const handleValidFormSubmit = useCallback(async () => {
    const fullApplicationHtml = getFormAsString({
      getBaseFormFieldProps,
      getFieldValueByPath,
      formTemplateOptions: templateOptions,
    });
    try {
      await submitHomeownerApplication({ fullApplicationHtml });

      setCorrectionsModalOpen(false);
      setHasBeValidationErrors(false);

      await onApplicationSubmittedRef.current?.();
    } catch (error: unknown) {
      const queryError = parseQueryError(error);
      if (queryError == null) {
        throw error;
      }

      const { status, data: untypedErrorData } = queryError;
      const errorData = parseResponse(untypedErrorData, ApplicationApiResponseSchema);
      let validationErrors;
      if (typeof errorData === 'object') {
        validationErrors = processValidationErrors(errorData.validationErrors);
      }

      if (status === 422 && validationErrors) {
        const errorTemplate = getInvalidFormTemplateFromAllTemplates(Object.keys(validationErrors));

        if (errorTemplate.length) {
          setHasBeValidationErrors(true);
          setCorrectionsModalOpen(true);
          showErrorsRef.current = true;
          correctionsTemplate.current = errorTemplate;
        } else {
          logError({
            eventType: 'applicationResponseWithErrors',
            detail: {
              errorData,
              message:
                'Error returned from POST /application did not contain paths found in the application form',
            },
          });
          // eslint-disable-next-line no-alert
          alert(applicationErrorMessage);
        }

        return;
      }

      setCorrectionsModalOpen(false);
      // eslint-disable-next-line no-alert
      alert(applicationErrorMessage);
    }
  }, [
    applicationErrorMessage,
    getBaseFormFieldProps,
    getFieldValueByPath,
    getInvalidFormTemplateFromAllTemplates,
    onApplicationSubmittedRef,
    submitHomeownerApplication,
    templateOptions,
  ]);

  const handleCorrectionsModalFormSubmit = useCallback(() => {
    setHiddenErrorPaths([]);
    const correctionsTemplatePreview = getInvalidFormTemplateFromAllTemplates();
    if (correctionsTemplatePreview.length > 0) {
      showErrorsRef.current = true;
      correctionsTemplate.current = correctionsTemplatePreview;
    }
    const invalidFields =
      correctionsTemplatePreview.length > 0
        ? getFieldsFromTemplate(correctionsTemplatePreview)
        : [];

    if (invalidFields.length > 0) {
      correctionsModalRef.current?.scrollTo?.({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      handleValidFormSubmit();
    }
  }, [handleValidFormSubmit, getInvalidFormTemplateFromAllTemplates]);

  const handleFormSubmit = async () => {
    showErrorMessagesRef.current = true;
    correctionsTemplate.current = getInvalidFormTemplateFromAllTemplates();
    if (!correctionsTemplate.current.length) {
      await handleValidFormSubmit();
    } else {
      setCorrectionsModalOpen(true);
    }
  };

  return (
    <styles.PageFormStyle>
      <styles.FormStyle>
        <FormTemplate
          template={pageTemplateMap[page](templateOptions)}
          getBaseFormFieldProps={getBaseFormFieldProps}
          getFieldValueByPath={getFieldValueByPath}
        />
        <ApplicationPageFooterNav
          totalNumPages={totalNumPages}
          currentPageNum={currentPageNum}
          page={page}
          onPaginate={onPaginate}
          onFormSubmit={handleFormSubmit}
          isProcessing={isProcessing}
        />
        <CorrectionsModal
          strongWarning={showErrorsRef.current === true}
          isOpen={correctionsModalOpen}
          correctionsTemplate={correctionsTemplate.current}
          onModalClose={handleCorrectionModalClose}
          getBaseFormFieldProps={getBaseFormFieldProps}
          getFieldValueByPath={getFieldValueByPath}
          onFormSubmit={handleCorrectionsModalFormSubmit}
          useAltHeaderText={hasBeValidationErrors}
          _ref={correctionsModalRef}
        />
      </styles.FormStyle>
    </styles.PageFormStyle>
  );
};

export default ApplicationForm;
